import { useDisclosure } from "@chakra-ui/react";
import React, { useCallback, useEffect, useState } from "react";
import { CiSearch } from "react-icons/ci";
import { useDispatch, useSelector } from "react-redux";
import { getAllUserPosts } from "../../redux/peoplePost/peoplepost.actions";
import DashboardLayout from "../DashboardHome/DashboardLayout";
import SingleUserPost from "../SingleUserPost/SingleUserPost";
import { setIsLoading } from "../../redux/features/features.action";
import AccountModal from "../Modal/AccountModal";
import { getAllUserPost, searchEvents } from "../../redux/api";
import DashboardSide from "../DashboardHome/DashboardSide";
import {
  logOut,
  setModalStatus,
  setStatus,
} from "../../redux/auth/auth.actions";
import dayjs from "dayjs";
import RelativeTime from "dayjs/plugin/relativeTime";

function Explore() {
  const uploadModalStatus = useSelector((state) => state.auth.showUploadModal);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const darktheme = useSelector((state) => state.features.settings.darktheme);
  const [currentPostId, setCurrentPostId] = useState(0);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.accessToken);
  const { user } = useSelector((state) => state.auth);
  const [suspended, setSuspended] = useState(false);
  const [page] = useState(1);
  const [, setLoadingMore] = useState(false);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [, setTotalPages] = useState(0);
  const [newDoc, setNewDoc] = useState(null);
  const [, setIndex] = useState(2);
  const [reportModal, setReportModal] = useState(false);
  const [, setActivePostId] = useState("");

  dayjs.extend(RelativeTime);

  const [events, setEvents] = useState([]);
  useEffect(() => {
    dispatch(getAllUserPosts());
    dispatch(setIsLoading(true));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isOpen === false) {
      setCurrentPostId(null);
    }
    // eslint-disable-next-line
  }, [isOpen]);

  const handleSinglePostModal = (post) => {
    if (user.suspend === true) {
      setSuspended(true);
    } else {
      setCurrentPostId(post?.id);
      onOpen();
      dispatch(setModalStatus(60));
    }
  };

  const callBack = () => {
    dispatch(getAllUserPosts());
  };

  const handleSearch = async (more = false) => {
    setLoading(false);
    try {
      !more && setLoading(true);
      const { data } = await searchEvents({ page, search });
      if (data) {
        const documents = data?.hits?.map((item) => {
          return {
            ...item.document,
            media: {
              images: item.document["media.images"],
              videos: item.document["media.videos"],
            },
          };
        });
        setEvents(page === 1 ? documents : [...events, ...documents]);
        setTotalPages(Math.ceil(data.found / data.request_params.per_page));
        setLoading(false);
        setLoadingMore(false);
      }
    } catch (error) {
      setLoading(false);
      // Sentry.Native.captureException(error);
    }
  };

  const visibleTodos = useCallback(() => {
    getEvents(true);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    visibleTodos();
    // eslint-disable-next-line
  }, []);

  const getData = () => {
    visibleTodos();
  };

  const getEvents = useCallback(
    async (more) => {
      try {
        setLoading(true);
        // more ? setLoadingMore(true) : setLoading(true);
        const { data } = await getAllUserPost({ more, newDoc });

        if (data) {
          setNewDoc(data?.lastDoc);
          setEvents([...events, ...data.events]);
          setLoading(false);
          setLoadingMore(true);
        }
      } catch (error) {
        setLoading(false);
      }
      setIndex((prevIndex) => prevIndex + 1);
    },
    // eslint-disable-next-line
    [newDoc]
  );

  useEffect(() => {
    if (user.isDeleted) {
      dispatch(setStatus(true));
      dispatch(logOut());
    }
    // eslint-disable-next-line
  }, [user]);

  const reportPost = (id) => {
    setActivePostId(id);
    setReportModal(!reportModal);
  };
  return (
    <DashboardLayout>
      <div
        className={`w-full h-full  ${
          darktheme
            ? "bg-kdark3 text-[#F9FAFB]"
            : "bg-[#fff] text-[text-[#27272E]"
        }`}
      >
        <div className="max-w-[600px] mx-auto  pt-[2rem]">
          <div
            className={`flex items-center w-full h-[50px] px-5 ${
              darktheme ? "bg-[black]" : "bg-[#F9FAFB]"
            } rounded-[43px]`}
          >
            <input
              onKeyDown={handleSearch}
              onChange={(e) => setSearch(e.target.value)}
              placeholder="Search Community"
              className="w-full h-full bg-transparent rounded-[43px] border-none outline-none"
            />
            <CiSearch size={24} onClick={handleSearch} />
          </div>

          <div
            className={`  max-w-[600px]  mx-auto h-[100vh]  rounded-[5px] mt-[22px] ${
              darktheme ? "bg-kdark3 text-white " : "bg-[#fff] text-[#27272E] "
            } `}
            id="scrollableDiv"
            style={{ overflowY: "scroll" }}
          >
            <DashboardSide
              events={events}
              user={user}
              token={token}
              openReportModal={reportPost}
              callEvents={() => getEvents(true)}
              handleSinglePost={handleSinglePostModal}
              updateCounts={getData}
              isOpen={isOpen}
              onClose={onClose}
              loading={loading}
            />
          </div>
        </div>
      </div>
      {currentPostId && (
        <SingleUserPost
          id={currentPostId}
          isOpen={isOpen && uploadModalStatus === 60}
          onClose={onClose}
          updateCounts={callBack}
          handle={() => handleSinglePostModal()}
          explore={true}
        />
      )}
      {suspended && isOpen && (
        <AccountModal
          isOpen={suspended}
          onClose={() => setSuspended(false)}
          title={"Your account has been Suspended"}
          description="Figma ipsum component variant main layer. Flows star follower
       figma shadow connection polygon flows. Effect figjam ellipse union
       draft bullet figjam plugin star. Horizontal draft scrolling."
          type="Suspended"
        />
      )}
    </DashboardLayout>
  );
}

export default Explore;
