import React, { useState } from "react";
import { CiSearch } from "react-icons/ci";
import { searchEvents } from "../../redux/api";
import { FiSearch } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { viewSinglePost } from "../../redux/posts/posts.action";
import InfiniteScroll from "react-infinite-scroll-component";
import SkeletonLoader from "../Skeleton/PostLoader";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
const SearchSideBar = ({ theme }) => {
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [events, setEvents] = useState([]);
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.accessToken);
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const handleSearch = useCallback(async () => {
    setLoading(true);

    try {
      // !loadMore && setLoading(true);
      const { data } = await searchEvents({ page, search });

      if (data) {
        const documents = data?.hits?.map((item) => {
          return {
            ...item.document,
            media: {
              images: item.document["media.images"],
              videos: item.document["media.videos"],
            },
          };
        });
        setPage(page + 1);
        setEvents(page === 1 ? documents : [...events, ...documents]);
        setTotalPages(Math.ceil(data.found / data.request_params.per_page));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // Sentry.Native.captureException(error);
    }
  }, [page]);

  const handleSinglePostModal = (post) => {
    if (token) {
      if (user.suspend) {
        // setSuspended(true);
      } else {
        dispatch(
          viewSinglePost({
            postId: post?.id,
            modalId: 10,
            modalActive: true,
          })
        );
      }
    } else {
      navigate("/login");
    }
  };

  const getMoreData = useCallback(() => {
    handleSearch();
    // eslint-disable-next-line
  }, []);

  return (
    <div
      className={` w-full ${
        theme ? "bg-black" : "bg-white"
      } w-[375px] pt-[3rem] pl-[.7rem]`}
    >
      <InfiniteScroll
        dataLength={totalPages}
        next={getMoreData}
        hasMore={true}
        scrollableTarget="scrollableDiv"
        scrollThreshold={1}
        key={1}
        loader={
          <div className="w-full px-3 justify-center">
            <SkeletonLoader />
          </div>
        }
      >
        <h4 className="text-[1.4rem] font-normal">Search</h4>

        <div className="w-[90%] px-[1rem] h-[45px]  mt-5 rounded-[43px]  border-[#8B8D98] border-[.5px] flex items-center ">
          <CiSearch />
          <input
            className="pl-[11px] select-none focus:bg-none bg-transparent bg-none outline-none text-[15px] w-full h-full border-none  "
            placeholder="Search Community"
            name="search"
            type="text"
            onKeyDown={handleSearch}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>

        <h5 className="mt-[1rem] px-[.5rem] font-SF-Pro-Regular  font-[510] text-[1rem]">
          Results
        </h5>
        <div className=" px-[.5rem]">
          {events?.length > 0 &&
            events?.map((item) => (
              <div
                onClick={() => handleSinglePostModal(item)}
                className="flex mb-[.2rem] items-center justify-between"
              >
                <div className="flex items-center">
                  <FiSearch className=" text-[#60646c] text-[20px] mr-[1rem]" />
                  <h4 className="w-[13rem] truncate">{item?.title}</h4>
                </div>
                <div>
                  <div className="rounded-[12px] bg-black w-[48px]  h-[48px] ">
                    {item.media.images[0] && (
                      <img
                        src={item.media.images[0]}
                        alt="post pics"
                        className="w-full h-full object-cover rounded-[12px]"
                      />
                    )}
                  </div>
                </div>
              </div>
            ))}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export default SearchSideBar;
