import SharePost from "../SharePost";
import { useDisclosure } from "@chakra-ui/react";
import { AiOutlineHeart } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { likeEvent } from "../../../redux/posts/posts.action";
import { emotionsIcons, selectEmoticons } from "../../../constants/data";
import { motion } from "framer-motion";
import { FiSend } from "react-icons/fi";
import { FaRegComment, FaUser } from "react-icons/fa";
import { setEventCommentAction } from "../../../redux/peoplePost/peoplepost.actions";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

function UserPostShare({
  user,
  page,
  handle,
  index,
  theme,
  openReportModal,
  selectedPost,
  token,
  selectedPost: { id, likedBy, likeCount, commentCount, title },
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [like, setLike] = useState(false);
  const [emotions, setEmoticons] = useState("");
  const [liked, setLiked] = useState(false);
  const [likes, setLikes] = useState(0);
  const dispatch = useDispatch();
  const [emoticon, setEmoticon] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  const router = useLocation();

  const postLike = (item) => {
    setLike(!like);
    let payload = { reactionType: item };

    dispatch(likeEvent(id, payload))
      .then((data) => {
        const likeStatus = getLiked(data.data.likedBy);
        // updateCounts(data.data.likeCount);
        if (likes >= 0) {
          if (likeStatus) {
            getEmoticons(data.data.likedBy);
            setLikes(data.data.likeCount);
          } else {
            if (likes >= 1) setLikes(data.data.likeCount);
            setEmoticons("");
          }
        }
      })
      .catch((err) => {});
  };

  const getLiked = (data) => {
    if (data && data.length === 0) return false;
    else if (data && data.find((item) => item.user === user.username))
      return true;
  };

  const getEmoticons = (data) => {
    // eslint-disable-next-line
    data?.map((each) => {
      if (each?.user === user?.username) {
        setEmoticons(each?.reaction);
      }
    });
  };

  const clickIcon = (item, e) => {
    if (token) {
      e.stopPropagation();
      setEmoticon(item);
      postLike(item);
      setLike(!like);
    } else {
      navigate("/login");
    }
  };

  const ownership = () => {
    if (likedBy && likedBy?.length === 0) setEmoticons("");
    else if (
      likedBy !== undefined &&
      likedBy?.find((item) => item.user === user?.username)
    ) {
      // eslint-disable-next-line
      likedBy?.map((each) => {
        if (each.user === user?.username) {
          setEmoticons(each.reaction);
          setLiked(true);
        }
      });
    } else setEmoticons("");
  };

  useEffect(() => {
    ownership();
    // eslint-disable-next-line
  }, [likedBy]);

  useEffect(() => {
    setLikes(likeCount);
    // eslint-disable-next-line
  }, [likedBy]);

  const handlePostComment = (eventId) => {
    if (message !== "") {
      dispatch(setEventCommentAction(message, eventId));
      handle();
      setMessage("");
    }
  };

  const handleChange = (e) => {
    // setMessage(e.target.value);
    handle(selectedPost);
  };

  return (
    <div key={index} className="px-4 ">
      <div className="flex items-center my-[.3rem] text-[#475467] text-[12px] ">
        <p className="mr-1 ">{likes} </p>
        <p className="">Reactions </p>
        <div className="w-[5px] h-[5px] bg-[#98A2B3] mx-[.5rem] rounded-full"></div>
        <p className="mr-1 ">{commentCount}</p> <p>comments</p>
      </div>

      {likedBy && (
        <div
          className={`flex justify-between items-center   ${
            theme ? "bg-kdark3 text-white" : "bg-[#fff] text-[#27272E]"
          } `}
        >
          <div className="flex">
            <div className="flex justify-between items-center">
              <div
                className={`flex items-center px-[rem] py-[.2rem] rounded-[15px] ${
                  theme ? " text-white" : " text-[#27272E]"
                } `}
              >
                <div className="flex w-full  group relative items-center cursor-pointer ">
                  <button
                    class={`invisible group-hover:visible
            absolute h-[50px] left-0 bottom-5 pb-2 
            ml-0 flex justify-around  items-center rounded-[15px] cursor-pointer w-[200px] ${
              theme ? "bg-kdark3 text-white" : "bg-[#fff] text-[#27272E]"
            }  `}
                  >
                    {emotionsIcons?.map((item, i) => (
                      <motion.img
                        initial={{ x: -100 }}
                        animate={{ x: 0 }}
                        exit={{ x: -100 }}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        src={item?.icon}
                        alt={item?.name}
                        key={i}
                        className="object-contain w-[13%]"
                        onClick={(e) => clickIcon(item?.name, e)}
                      />
                    ))}
                  </button>

                  {like ? (
                    selectEmoticons(emoticon)
                  ) : liked ? (
                    <span onClick={(e) => clickIcon("", e)}>
                      {selectEmoticons(emotions)}
                    </span>
                  ) : (
                    <AiOutlineHeart
                      className=""
                      size={24}
                      // onClick={(e) => clickIcon("love", e)}
                    />
                  )}
                </div>
              </div>
              <button
                onClick={() => handle(selectedPost)}
                className={`flex items-center ml-[.5rem] cursor-pointer px-[.7rem] py-[.2rem]   ${
                  theme ? " text-white" : " text-[#27272E]"
                } `}
              >
                <FaRegComment className="text-[1rem]" size={24} />
              </button>
            </div>
            <div
              onClick={onOpen}
              className={`flex ml-[.5rem] justify-center items-center py-[.2rem] ${
                theme ? " text-white" : " text-[#27272E]"
              } `}
            >
              <FiSend size={24} />
            </div>
          </div>
        </div>
      )}
      {router?.pathname.includes("/dashboard/explore") && page === "shared" ? (
        ""
      ) : token === undefined && page !== "shared" ? (
        <div className="w-full  h-full  flex mt-[.5rem] items-center">
          <div>
            <div className="w-[32px] h-[32px] bg-[#FCBE5A] rounded-[50%] flex justify-center items-center">
              <FaUser />
            </div>
          </div>

          <div className="w-full flex border-[#00003B0D] bg-[#F7F7F7] items-center rounded-[31px] ml-2 px-4">
            <div className="w-full   ">
              <input
                className="w-full h-full bg-transparent text-[.6rem] py-[.8rem] border-none outline-none px-2"
                placeholder="Login to post a comment...."
              />
            </div>
          </div>
        </div>
      ) : (
        page !== "single" &&
        page !== "shared" && (
          <div className="w-full h-full flex mt-[.5rem] py-[rem] items-center">
            <div className="w-[32px] h-[32px] rounded-[50%]">
              <img
                alt="user"
                className="w-full h-full rounded-[50%]"
                src={user?.imageUrl}
              />
            </div>
            <div className="w-[90%] flex border-[#00003B0D] border-[1px] bg-[#F7F7F7] h-[36px] items-center rounded-[31px] ml-3  px-4">
              <div className="w-full">
                <input
                  className="w-full h-full  bg-transparent text-[12px] py-[.8rem]  border-none outline-none px-2"
                  value={message}
                  placeholder="Add a Comment...."
                  onChange={(e) => handleChange()}
                />
              </div>
              <p
                className="text-[#FC5059]  text-[.8rem] font-bold cursor-pointer"
                onClick={() => handlePostComment(id)}
              >
                Post
              </p>
            </div>
          </div>
        )
      )}
      {
        <SharePost
          theme={theme}
          isOpen={isOpen}
          onClose={onClose}
          id={id}
          title={title}
        />
      }
    </div>
  );
}

export default UserPostShare;
