import React, { useState } from "react";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import DashboardNavigation from "./DashboardMenu";
import TitleBarNav from "./Title";
import SearchSideBar from "./SearchSideBar";
import Alert from "../AlertComponent/Alert";

const DashboardLayout = ({ children }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const darktheme = useSelector((state) => state.features.settings.darktheme);
  const barStatus = useSelector((state) => state.auth.leftSideBar);
  const router = useLocation();

  return (
    <>
      <div
        className={`h-screen relative flex justify-content items-center  ${
          darktheme ? "bg-kdark3 text-white" : "bg-[#fafafa] text-[#27272E]"
        }`}
      >
        <aside
          className={`hidden  h-screen ${
            barStatus?.showSearchBar ? "w-[110px]" : "w-[300px]"
          } overflow-y-auto lg:block border-r`}
        >
          <DashboardNavigation pathname={router.pathname} />
        </aside>
        {barStatus?.showSearchBar && (
          <aside
            className={` h-full fixed z-20 left-[102px] shadow-r-lg overflow-y-scroll ${
              darktheme ? "bg-black" : "bg-white"
            } w-[375px]`}
          >
            {barStatus?.screen === "search" ? (
              <SearchSideBar theme={darktheme} />
            ) : (
              <Alert />
            )}
          </aside>
        )}
        <div className="h-screen w-full mx-auto  overflow-y-auto overflow-x-hidden">
          <div className="sticky top-0 z-[20] b">
            <TitleBarNav
              menuOpen={menuOpen}
              setMenuOpen={setMenuOpen}
              pathname={router?.pathname}
            />
          </div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
            className=""
          >
            {children}
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default DashboardLayout;
