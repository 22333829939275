import React from "react";
import { AiOutlineLock } from "react-icons/ai";
import { BiLike, BiLogOutCircle } from "react-icons/bi";
import { FiUser } from "react-icons/fi";
import { MdKeyboardArrowRight, MdOutlineLocationOn } from "react-icons/md";
import { RiChatSmileLine, RiDeleteBinLine } from "react-icons/ri";
import { TbArrowsRightLeft } from "react-icons/tb";
import { BsVectorPen } from "react-icons/bs";
import { useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import EditProfile from "./EditProfile";
import ChangePassword from "./ChangePassword";
import ChangeLocation from "./ChangeLocation";
import Support from "./SupportHelp";
import Switch from "./SwitchAccount";
import { useEffect } from "react";
import DashboardLayout from "../DashboardHome/DashboardLayout";
import DeleteAccount from "./DeleteAccount";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../redux/auth/auth.actions";
import { useNavigate } from "react-router-dom";
import ThemeToggle from "../ThemeButton/Toggle";

function Account() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [accountModalId, setAccountModalId] = useState(1);
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const darktheme = useSelector((state) => state.features.settings.darktheme);
  const handleAccountModal = (id) => {
    setAccountModalId(id);
    onOpen();
  };

  useEffect(() => {});

  const handleLogout = () => {
    dispatch(logOut());
    window.location.reload(false);
    navigate("/dashboard/home");
  };
  return (
    <DashboardLayout>
      <div className="w-full">
        <div className="w-[80%] mt-[35px] mr-[130px]">
          <div className="w-full border-b border-[#cac8c8] font-semibold text-kbody6 pb-[15px]">
            <div className="w-full flex items-center pl-[41px]">
              <span className="ml-5 text-[24px]">Account</span>
            </div>
          </div>
          {/* tabs */}
          <div className="pl-[41px] pt-[29px]">
            <div className="flex justify-between flex-wrap">
              {/* account */}
              <div className="flex flex-col basis-[40%]">
                <div className="mb-[23px] uppercase text-kbody7">Account</div>
                {/* account subcategorie */}
                <div>
                  <div
                    className="flex justify-between cursor-pointer items-center mb-[28px]"
                    onClick={() => handleAccountModal(1)}
                  >
                    <div className="flex">
                      <span>
                        <FiUser
                          size={"25px"}
                          className={`${
                            darktheme ? "bg-[#09121F]" : "text-kdark3"
                          }`}
                        />
                      </span>
                      <span className="ml-[19px] text-kbody6">
                        Edit profile
                      </span>
                    </div>
                    <div>
                      <MdKeyboardArrowRight />
                    </div>
                  </div>
                  <div
                    className="flex justify-between cursor-pointer items-center mb-[28px]"
                    onClick={() => handleAccountModal(2)}
                  >
                    <div className="flex">
                      <span>
                        <MdOutlineLocationOn
                          size={"25px"}
                          className={`${
                            darktheme ? "bg-[#09121F]" : "text-kdark3"
                          }`}
                        />
                      </span>
                      <span className="ml-[19px]  text-kbody6">
                        Change location
                      </span>
                    </div>
                    <div>
                      <MdKeyboardArrowRight />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col basis-[40%]">
                <div className="mb-[23px] uppercase text-kbody7">Activity</div>
                {/* activity subcategorie */}
                <div>
                  <div className="flex cursor-pointer menu justify-between items-center mb-[28px]">
                    <div className="flex">
                      <span>
                        <BsVectorPen
                          size={"25px"}
                          className={`${
                            darktheme ? "bg-[#09121F]" : "text-kdark3"
                          }`}
                        />
                      </span>
                      <span className="ml-[19px] text-kbody6">Appearance</span>
                    </div>
                    <ThemeToggle />
                  </div>
                  <div
                    className="flex justify-between cursor-pointer items-center mb-[28px]"
                    onClick={() => handleAccountModal(3)}
                  >
                    <div className="flex">
                      <span>
                        <AiOutlineLock
                          size={"25px"}
                          className={`${
                            darktheme ? "bg-[#09121F]" : "text-kdark3"
                          }`}
                        />
                      </span>
                      <span className="ml-[19px] text-kbody6">
                        Change password
                      </span>
                    </div>
                    <div>
                      <MdKeyboardArrowRight />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col basis-[40%]">
                <div className="mb-[23px] uppercase text-kbody7">Support</div>
                {/* activity subcategorie */}
                <div>
                  <div
                    className="flex cursor-pointer justify-between items-center mb-[28px]"
                    onClick={() => handleAccountModal(5)}
                  >
                    <div className="flex">
                      <span>
                        <RiChatSmileLine
                          size={"25px"}
                          className={`${
                            darktheme ? "bg-[#09121F]" : "text-kdark3"
                          }`}
                        />
                      </span>
                      <span className="ml-[19px] text-kbody6">
                        Help & Support
                      </span>
                    </div>
                    <div>
                      <MdKeyboardArrowRight />
                    </div>
                  </div>
                  <div className="flex cursor-pointer justify-between items-center mb-[28px]">
                    <div className="flex">
                      <span>
                        <BiLike
                          size={"25px"}
                          className={`${
                            darktheme ? "bg-[#09121F]" : "text-kdark3"
                          }`}
                        />
                      </span>
                      <span className="ml-[19px] text-kbody6">
                        Rate aradugbo in store
                      </span>
                    </div>
                    <div>
                      <MdKeyboardArrowRight />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col basis-[40%]">
                <div className="mb-[23px] uppercase text-kbody7">Manage</div>
                <div>
                  <div
                    className="flex justify-between cursor-pointer items-center mb-[28px]"
                    onClick={() => handleAccountModal(4)}
                  >
                    <div className="flex">
                      <span>
                        <TbArrowsRightLeft
                          size={"25px"}
                          className={`${
                            darktheme ? "bg-[#09121F]" : "text-kdark3"
                          }`}
                        />
                      </span>
                      <span className="ml-[19px] text-kbody6">
                        Switch account
                      </span>
                    </div>
                    <div>
                      <MdKeyboardArrowRight />
                    </div>
                  </div>
                  <div
                    onClick={handleLogout}
                    className="flex cursor-pointer justify-between items-center mb-[28px]"
                  >
                    <div className="flex">
                      <span>
                        <BiLogOutCircle
                          size={"25px"}
                          className={`${
                            darktheme ? "bg-[#09121F]" : "text-kdark3"
                          }`}
                        />
                      </span>
                      <span className="ml-[19px] text-kbody6">Logout</span>
                    </div>
                    <div>
                      <MdKeyboardArrowRight />
                    </div>
                  </div>
                  <div
                    className="flex cursor-pointer justify-between items-center mb-[28px]"
                    onClick={() => handleAccountModal(6)}
                  >
                    <div className="flex">
                      <span>
                        <RiDeleteBinLine size={"25px"} color={"#F97066"} />
                      </span>
                      <span className="ml-[19px] text-[#F97066] text-kbody6">
                        Delete Account
                      </span>
                    </div>
                    <div>
                      <MdKeyboardArrowRight />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {
        <EditProfile
          isOpen={accountModalId === 1 && isOpen}
          onClose={onClose}
          type={"Edit"}
        />
      }
      {
        <ChangePassword
          isOpen={accountModalId === 3 && isOpen}
          onClose={onClose}
        />
      }
      {
        <ChangeLocation
          isOpen={accountModalId === 2 && isOpen}
          onClose={onClose}
          location={user?.coords}
        />
      }
      {<Support isOpen={accountModalId === 5 && isOpen} onClose={onClose} />}
      {<Switch isOpen={accountModalId === 4 && isOpen} onClose={onClose} />}
      {
        <DeleteAccount
          isOpen={accountModalId === 6 && isOpen}
          onClose={onClose}
        />
      }
    </DashboardLayout>
  );
}

export default Account;
