import React from "react";
import { FaRegComment } from "react-icons/fa6";
import { RiQuestionnaireLine } from "react-icons/ri";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { AiOutlineUserAdd } from "react-icons/ai";
import Flvplayer from "./VideoPlayer/FlvPlayer";

function LivestreamCard({ stream }) {
  return (
    <div
      className="mx-auto w-[536px] border-b-[1px] border-[#02023414] mb-[1rem] pb-[1rem] cursor-pointer"
      // onClick={() => view(stream?.roomId)}
    >
      <div className="w-full relative h-[700px]  rounded-[10px]">
        {stream && stream?.liveStatus === 1 && stream?.roomId ? (
          <Flvplayer
            url={`https://livestream.myaradugbo.com/live/${stream?.roomId}.flv`}
            type="flv"
            className="rounded-[10px]"
          />
        ) : (
          <video controls className="w-full rounded-[10px] h-full object-cover">
            <source src={stream?.replayLink} type="video/mp4" />
            {/* <source src="movie.ogg" type="video/ogg" /> */}
            Your browser does not support the video tag.
          </video>
        )}
        <div className="ml-4 absolute text-white bottom-28 left-0">
          <div className="flex mb-1 items-center">
            <span className="bg-[#fc5c65] font-thin text-[12px] rounded-[15px] px-[.8rem] text-white">
              Live
            </span>
            <p className=" font-thin ml-[.5rem] text-[12px]">
              {stream?.viewers?.length} people watching
            </p>
          </div>
          <h4 className="text-white mb-[.5rem] font-bold ">{stream?.title}</h4>
          <div className="flex mb-1 items-center">
            <img
              src={stream?.host?.imageUrl}
              alt="User"
              className="w-[30px] h-[30px] rounded-full object-cover"
            />
            <p className="text-[.7rem] ml-[.5rem] capitalize  text-white">
              {stream?.host?.displayName || stream?.host?.username}
            </p>
          </div>
        </div>
        <div className="flex flex-col items-center gap-4 mt-4 absolute bottom-20 right-4 ">
          <img
            src={stream?.host?.imageUrl}
            alt="User"
            className="w-[48px] h-[48px] rounded-full object-cover"
          />
          <div className="flex h-[68px] w-[40px] text-white justify-between bg-[#838181c0] items-center p-[8px] flex-col backdrop-blur rounded-[25px]">
            <FaRegComment className="text-white w-[24px] h-[24px]" />
            <p>{stream?.questions?.length}</p>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center mt-4 w-fill ">
        <input
          placeholder="Comment..."
          className="w-[70%] px-[1rem] h-[40px] text-[12px] bg-transparent border-[1px] py-[.3rem] border-[#60646c] rounded-[20px]"
        />
        <div className="flex justify-between py-[.2rem] items-center w-[28%]">
          <RiQuestionnaireLine className="w-[24px] rounded-md ml-[1rem] h-[24px] text-[#60646c]" />
          <AiOutlineUserAdd className="w-[24px] h-[24px] text-[#60646c]" />
          <HiOutlineDotsHorizontal className="w-[24px] h-[24px] text-[#60646c]" />
        </div>
      </div>
    </div>
  );
}

export default LivestreamCard;
