import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Activity from "./Activity/Activity";
import AroundYou from "./AroundYou/AroundYou";

function Alert() {
  const darktheme = useSelector((state) => state.features.settings.darktheme);
  const AroundData = useSelector((state) => state.alert.AroundData);

  const [alertTab, setAlertTab] = useState(1);

  useEffect(() => {}, []);

  return (
    <div className={`w-full h-full   ${darktheme ? "bg-kdark3" : "bg-[#fff]"}`}>
      <div
        className={`w-full pt-[50px] ${darktheme ? "bg-kdark3" : "bg-[#fff]"}`}
      >
        <h4 className="ml-[13px] text-[24px] font-bold mb-[1rem]">Alert</h4>
        <div className="w-full flex justify-center border-b border-[#F5F5F5] font-semibold text-kbody6">
          <div
            className={`flex justify-center cursor-pointer w-[49%]  `}
            onClick={() => setAlertTab(1)}
          >
            <div
              className={`w-[90px] text-center mx-auto ${
                alertTab === 1
                  ? "border-b-[1px] border-[#F97066]"
                  : "border-none"
              }`}
            >
              <p className="font-light w-[90px]">Around You</p>
            </div>
          </div>
          <div
            className={`w-[90px] text-center mx-auto ${
              alertTab === 2 ? "border-b-[1px] border-[#F97066]" : "border-none"
            }`}
          >
            <p
              className={`flex font-light justify-center pb-[.7rem] cursor-pointer  `}
              onClick={() => setAlertTab(2)}
            >
              Activity
            </p>
          </div>
        </div>
        {alertTab === 1 && <AroundYou />}
        {alertTab === 2 && <Activity />}
      </div>
    </div>
  );
}

export default Alert;
