import React, { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  apple_circle_icon,
  facebook_icon,
  user_icon,
} from "../../constants/images";
import Verification from "../Verification/Verification";
import WithEmail from "./LoginComponent/WithEmail/WithEmail";
import { useDispatch, useSelector } from "react-redux";
import { GoogleLogin } from "@react-oauth/google";
import {
  setAuthLoading,
  setSignInByFacebook,
  setSignInByGoogle,
} from "../../redux/auth/auth.actions";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./Login.css";
import {
  // Appleprovider,
  auth,
  Facebookprovider,
} from "../../firebase/utils";
import {
  FacebookAuthProvider,
  // OAuthProvider,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  signInWithPopup,
} from "firebase/auth";
import flags from "react-phone-number-input/flags";
import WithUserName from "../SignUp/withUserName/WithUserName";
import Loader from "../Loader";
import EnterEmail from "./ResetPassword/EnterEmail";
import NewPassword from "./ResetPassword/NewPassword";
import { HiX } from "react-icons/hi";
import { IoChevronBack } from "react-icons/io5";
import AccountModal from "../Modal/AccountModal";
import AppleSignin from "react-apple-signin-auth";
import axios from "axios";

function Login() {
  const phoneLoading = useSelector((state) => state.auth.isLoading);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [verificationToken, setVerificationToken] = useState("");
  const [disable, setDisable] = useState(true);
  const dispatch = useDispatch();
  const [loginStep, setLoginStep] = useState(0);
  const navigate = useNavigate();
  const [country, setCountryCode] = useState(null);

  function onCaptchVerify() {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {
            onSignup();
          },
          "expired-callback": (error) => {},
        },
        auth
      );
    }
  }

  function onSignup() {
    dispatch(setAuthLoading(true));
    onCaptchVerify();
    const appVerifier = window.recaptchaVerifier;
    const formatPh = "+" + phoneNumber;

    if (appVerifier) {
      signInWithPhoneNumber(auth, formatPh, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          setVerificationToken(confirmationResult.verificationId);
          dispatch(setAuthLoading(false));

          setLoginStep(1);
        })
        .catch((error) => {
          console.log(error?.response);
          dispatch(setAuthLoading(false));
        });
    }
  }

  const handleroute = () => {
    navigate("/signup");
  };

  const handleloginStep = (id) => {
    setLoginStep(id);
  };

  const handlePhoneLogin = (e) => {
    e.preventDefault();

    onSignup();
  };

  // const loginWithApple = () => {
  //   signInWithPopup(auth, Appleprovider)
  //     .then((result) => {
  //       console.log(result);
  //       // The signed-in user info.
  //       // const user = result.user;

  //       // Apple credential
  //       const credential = OAuthProvider.credentialFromResult(result);
  //       const accessToken = credential.accessToken;
  //       const idToken = credential.idToken;
  //       dispatch(setSignInByApple({ token: accessToken, secret: idToken }));
  //       // IdP data available using getAdditionalUserInfo(result)
  //     })
  //     .catch((error) => {
  //       // Handle Errors here.
  //       if (error) {
  //         console.log(error.response);
  //       }
  //       // ...
  //     });
  // };

  const loginFacebook = () => {
    signInWithPopup(auth, Facebookprovider)
      .then((result) => {
        // The signed-in user info.
        // const user = result.user;

        // This gives you a Facebook Access Token. You can use it to access the Facebook API.
        const credential = FacebookAuthProvider.credentialFromResult(result);
        const accessToken = credential.accessToken;
        dispatch(setSignInByFacebook({ idToken: accessToken }));
        // IdP data available using getAdditionalUserInfo(result)
        // ...
      })
      .catch((error) => {
        // Handle Errors here.
        if (error) {
        }
      });
  };

  const goHome = () => {
    navigate("/");
  };

  const handleChange = (value) => {
    setPhoneNumber(value);
    if (value?.length >= 11) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  };

  const goBack = () => {
    setLoginStep(0);
  };

  const getData = async () => {
    try {
      const res = await axios.get(
        `https://ipinfo.io/json?token=efece21c2df820`
      );
      if (res) {
        setCountryCode((res?.data?.country).toLowerCase());
      }
    } catch (err) {
      console.log(err?.response);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="w-[438px] flex relative flex-col justify-center sm:w-[500px] px-[50px] ">
      <div className=" mb-[37px] flex items-center  justify-between ">
        <div
          className={`${
            loginStep === 0 ? "bg-white text-white" : ""
          }  w-[30px] flex align-middle text-[#344054] items-center h-[30px] rounded-[50%] bg-[#F2F4F7] text-center cursor-pointer`}
          onClick={goBack}
        >
          <IoChevronBack
            size={18}
            color=""
            className={`${
              loginStep === 0 ? "text-white" : ""
            } mx-auto  text-center`}
          />
        </div>
        {/* ))} */}
        <div className="align-center text-center">
          {loginStep === 1 && (
            <h4 className=" text-center text-kdark3 text-[24px]">
              Enter verification code
            </h4>
          )}
          {(loginStep === 0 || loginStep === 2) && (
            <h4 className=" text-center text-kdark3 text-[24px]">
              Log in to Aradugbo
            </h4>
          )}
          {(loginStep === 4 || loginStep === 5) && (
            <h4 className=" text-center text-kdark3 text-[24px]">
              Reset Password
            </h4>
          )}
        </div>
        <div
          className=" w-[30px] flex align-middle items-center h-[30px] rounded-[50%] bg-[#F2F4F7] text-center cursor-pointer"
          onClick={goHome}
        >
          <HiX
            size={18}
            color=""
            className="text-[#344054] mx-auto  text-center"
          />
        </div>
      </div>
      {loginStep === 0 && (
        <>
          {country && (
            <form className="w-full" onSubmit={handlePhoneLogin}>
              <p className="text-[.8rem] text-[#718096]">Phone</p>

              <PhoneInput
                className="w-full phone-input rounded-[4px]  py-[rem] mt-3"
                country={country}
                label="Phone"
                value={phoneNumber}
                inputProps={{
                  name: "phone",
                  required: true,
                  autoFocus: true,
                }}
                onChange={handleChange}
                flags={flags}
              />

              <div
                className={`flex justify-center items-center w-full h-[48px] mt-[21px] rounded-[5px]   ${
                  disable
                    ? "bg-gray-200 text-[#525050]"
                    : "bg-[#F04438] text-white"
                }`}
              >
                {phoneLoading ? (
                  <Loader />
                ) : (
                  <button
                    type="submit"
                    disabled={disable}
                    className="w-full h-full border-none outline-none"
                  >
                    Continue
                  </button>
                )}
              </div>
            </form>
          )}
          <div className="flex my-[22px] justify-center text-[16px]">OR</div>
          {/* other method */}
          <div className="w-[400px]">
            {/* email/username */}
            <div
              onClick={() => handleloginStep(2)}
              className="w-full flex items-center cursor-pointer mb-4 h-[54px] px-5 border border-[#02023414] bg-[#05055806] rounded-[5px]"
            >
              <div>
                <img src={user_icon} alt="icon" />
              </div>
              <p className="w-full text-[#718096] flex justify-center">
                Use email / username
              </p>
            </div>
            {/* facebook */}
            <div
              onClick={loginFacebook}
              className="mx-auto text-[#718096] justify-center flex items-center mb-4 h-[48px] py-[.5rem] px-5 border cursor-pointer border-[#02023414] bg-[#05055806] rounded-[5px]"
            >
              <div>
                <img src={facebook_icon} alt="icon" />
              </div>
              <p className="w-full text-[#718096] flex justify-center">
                Continue with Facebook
              </p>
            </div>

            {/* Apple */}
            <div
              className="w-[100%] flex items-center mb-4 h-[54px] 
 px-5 border cursor-pointer border-[#02023414] bg-[#05055806] rounded-[5px]
"
            >
              <AppleSignin
                authOptions={{
                  clientId: "com.efficion.arad",
                  scope: "email name",
                  redirectURI: "https://www.myaradugbo.com/login",
                  state: "state",
                  nonce: "nonce",
                  usePopup: true,
                }}
                uiType="light"
                className="apple-auth-btn"
                noDefaultStyle={false}
                onSuccess={(response) => console.log(response)} // default = undefined
                onError={(error) => console.error(error)} // default = undefined
                skipScript={false} // default = undefined
                iconProp={{ style: { marginTop: "10px" } }} // default = undefined
                render={(props) => (
                  <div className="flex w-full " {...props}>
                    <div>
                      <img src={apple_circle_icon} alt="icon" />
                    </div>
                    <p className="w-full text-[#718096] flex justify-center">
                      Continue with Apple
                    </p>
                  </div>
                )}
              />
            </div>
            {/* Google */}
            <div className="mb-4 text-[1rem] bg-[#05055806] border-[#02023414]  rounded-[5px] mx-auto w-[100%]  flex justify-center ">
              <GoogleLogin
                className="w-[100%] py-[.8rem]   bg-[#05055806] border-none"
                style={{
                  background: "#05055806",
                  border: "0px",
                  padding: " .5rem 0px",
                }}
                width="400px"
                heigth="100px"
                icon={false}
                onSuccess={(credentialResponse) => {
                  dispatch(
                    setSignInByGoogle(
                      {
                        idToken: credentialResponse.credential,
                      },
                      navigate
                    )
                  );
                }}
                onError={() => {}}
              />
            </div>
          </div>
        </>
      )}
      {loginStep === 1 && (
        <Verification
          auth={auth}
          phoneNumber={phoneNumber}
          handleloginStep={handleloginStep}
          verificationToken={verificationToken}
        />
      )}
      {loginStep === 2 && <WithEmail handleloginStep={handleloginStep} />}
      {loginStep === 3 && <WithUserName handleloginStep={handleloginStep} />}
      {loginStep === 4 && <EnterEmail handleloginStep={handleloginStep} />}
      {loginStep === 5 && <NewPassword />}
      {loginStep === 5 && <AccountModal />}
      <div
        className={`flex text-[#718096] w-full justify-center absolute -bottom-[2rem] right-auto left-auto  mt-[50px] cursor-pointer text-[16px] ${
          loginStep === 1 && " "
        }`}
        onClick={handleroute}
      >
        <p className="text-left ml-[5rem] w-full">
          {" "}
          Don't have an account?{" "}
          <span className="text-[#F04438]"> Sign up</span>
        </p>
      </div>
    </div>
  );
}

export default Login;
