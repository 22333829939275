import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getUser, updateUserProfile } from "../../../redux/auth/auth.actions";
import parsePhoneNumber from "libphonenumber-js";
import PhoneInput from "react-phone-number-input";

function EditProfile({ isOpen, onClose, type }) {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [inputValue, setInputValue] = useState({
    username: "",
    phoneNumber: "",
    displayName: "",
    sex: "",
    dob: "",
    email: "",
  });

  const [error, setError] = useState({
    phone: "",
    sex: "",
    dob: "",
    response: "",
  });
  const [, setIsError] = useState(false);

  useEffect(() => {
    if (user) {
      setInputValue({
        countryCode:
          user?.phoneNumber !== undefined
            ? parsePhoneNumber(user?.phoneNumber)?.country
            : "",
        username: user?.username,
        phoneNumber: user?.phoneNumber !== undefined ? user?.phoneNumber : "",
        displayName: user?.displayName,
        sex: "",
        dob: moment(user?.dob).format("YYYY-MM-DD"),
        email: user?.email,
      });
    }
    // eslint-disable-next-line
  }, [user]);

  const handlePhoneNumber = (text) => {
    if (text?.length < 10) {
      setIsError(true);
      setError({ ...error, phone: "This is less than 10 digits" });
    } else if (text?.length > 10) {
      setInputValue({
        ...inputValue,
        phoneNumber: text,
      });
      setIsError(true);
      setError({ ...error, phone: "This is more than 10 digits" });
    } else {
      setIsError(false);
      setError({ ...error, phone: "" });
    }
  };

  useEffect(() => {
    dispatch(getUser());
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    setInputValue({
      ...inputValue,
      [e.target.name]: e.target.value,
    });
  };

  const updateProfile = (e) => {
    e.preventDefault();
    if (inputValue?.phoneNumber?.length < 10) {
      setIsError(true);
      setError({ ...error, phone: "Your phone number is incomplete" });
      return;
    }

    const payload = {
      username: inputValue?.username,
      phoneNumber: inputValue?.phoneNumber,
      displayName: inputValue?.displayName,
      sex: "",
      dob: inputValue?.dob,
      email: inputValue?.email,
      coords: { latitude: 6, longitude: 3 },
    };
    dispatch(updateUserProfile(user?.username, payload));
  };

  return (
    <Modal className="w-full" isOpen={isOpen} onClose={onClose} size={"xl"}>
      <ModalOverlay />
      <ModalContent
        className="w-full overflow-auto "
        containerProps={{ justifyContent: "center" }}
      >
        <ModalHeader color="#2B2B2B" fontWeight={700} fontSize="21px">
          <div className="w-full flex justify-center">{type} Profile</div>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <form className="w-full pb-[2rem]" onSubmit={(e) => updateProfile(e)}>
            {/* <div>
              <div className="w-[150px] bg-black mx-auto rounded-[50%] h-[150px] border border-[#CBD5E0] justify-between items-center ">
                {user?.imageUrl ? (
                  <img
                    src={user?.imageUrl}
                    alt="user"
                    className="w-[100%] h-[100%] object-cover rounded-[50%]"
                  />
                ) : (
                  ""
                )}
              </div>
            </div> */}
            <div>
              <label className="mb-[15px]">Username</label>
              <div className="flex h-[54px] border border-[#CBD5E0] rounded-[4px] justify-between items-center ">
                <input
                  className="p-[.7rem] outline-none w-full "
                  value={inputValue?.username}
                  type="text"
                  name="username"
                  placeholder="e.g Anthony Samuel"
                  disabled
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
            <div className="mt-[18px]">
              <label className="mb-[15px]">Display Name</label>
              <div className="flex h-[54px] rounded-[4px] border border-[#CBD5E0] justify-between items-center ">
                <input
                  className="p-[.7rem] outline-none w-full"
                  value={inputValue?.displayName}
                  type="text"
                  required
                  name="displayName"
                  placeholder="e.g Anthony"
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
            <div className="mt-[18px]">
              <label className="mb-[15px]">Email Address</label>
              <div className="flex h-[54px] rounded-[4px] border border-[#CBD5E0] justify-between items-center ">
                <input
                  className="p-[.7rem] outline-none w-full"
                  value={inputValue?.email}
                  type="email"
                  name="email"
                  placeholder="e.g Anthonysam@gmail.com"
                  disabled={inputValue?.email === "" ? false : true}
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
            <div className="mt-[18px]">
              <label className="mb-[15px]">Phone</label>
              <div className="flex h-[54px] rounded-[4px] border border-[#CBD5E0] justify-between items-center ">
                <PhoneInput
                  value={inputValue?.phoneNumber}
                  onChange={(e) => handlePhoneNumber(e)}
                  name="phoneNumber"
                  className="p-[.7rem] outline-none border-none outline-0 w-full flex"
                  placeholder="Enter phone number"
                  layout="second"
                  required
                />
              </div>
            </div>
            <div className="mt-[18px]">
              <label className="mb-[15px]">Date of Birth </label>
              <div className="flex h-[54px] rounded-[4px] border border-[#CBD5E0] justify-between items-center ">
                <input
                  className="p-[.7rem] outline-none w-full"
                  value={inputValue?.dob}
                  name="dob"
                  placeholder="Birth Date"
                  type="date"
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </div>
            {type === "Edit" && (
              <button
                type="submit"
                className="flex justify-center items-center w-full h-[48px] my-[2rem] rounded-[5px] text-white hover:bg-[#D0D5DD] bg-[#F04438]"
              >
                Save
              </button>
            )}
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default EditProfile;
