import React, { useState, useEffect } from "react";
import ReactMapboxGl, { Marker } from "react-mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
/* eslint-disable import/no-webpack-loader-syntax */
import mapboxgl from "mapbox-gl";
import Logo from "../../assets/images/logo.png";
// @ts-ignore

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const Map = ReactMapboxGl({
  accessToken:
    "pk.eyJ1IjoiYWRtaW5lZmZpY2lvbiIsImEiOiJjbGI2d2JvZHUwNTFsM3ZvZGhmaHYxZ21pIn0.LCwPwD2DbgM0piyDwL4CJw",
  logoPosition: "bottom-right",
});

const MapBox = ({ mapEvents }) => {
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [, setError] = useState(null);

  useEffect(() => {
    if (!navigator.geolocation) {
      setError("Geolocation is not supported by your browser");
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setLatitude(latitude);
          setLongitude(longitude);
          // setCurrentLocation({ longitude, latitude });
        },
        (error) => {
          console.error("Error getting location:", error);
        },
        { enableHighAccuracy: true, maximumAge: 0, timeout: 60000 }
      );
      // navigator.geolocation.getCurrentPosition(successHandler, errorHandler);
    }
  }, []);

  return (
    <>
      {longitude && latitude && (
        <Map
          // eslint-disable-next-line
          onBoxZoomEnd={(item, e) => {
            item.flyTo({
              center: [longitude, latitude],
            });
          }}
          renderChildrenInPortal={true}
          // eslint-disable-next-line
          style="mapbox://styles/mapbox/streets-v8?optimize=true"
          center={[longitude, latitude]}
          zoom={[10]}
          movingMethod="easeTo"
          containerStyle={{
            height: "100vh",
            width: "100%",
          }}
          className="w-[90%]"
        >
          <Marker
            coordinates={[longitude, latitude]}
            anchor="bottom"
            className=" w-[30px] h-[30px] rounded-[50%] bg-white p-[.3rem]"
          >
            <img
              src={Logo}
              alt="logo"
              className="object-contain  rounded-[50%] w-full h-full"
            />
          </Marker>
          <>
            {mapEvents &&
              mapEvents?.map((item, i) => (
                <Marker
                  key={i}
                  coordinates={[
                    item?.coords?.longitude,
                    item?.coords?.latitude,
                  ]}
                  anchor="bottom"
                  className=" w-[30px] h-[30px] rounded-[50%] bg-white p-[.2rem]"
                >
                  <img
                    src={Logo}
                    alt="logo"
                    className="rounded-[50%] object-contain w-full h-full"
                  />
                </Marker>
              ))}
          </>
        </Map>
      )}
    </>
  );
};

export default MapBox;
