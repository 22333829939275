import React from "react";
import { useState } from "react";
import { useDisclosure } from "@chakra-ui/react";
import { useEffect } from "react";
import DashboardLayout from "../DashboardHome/DashboardLayout";
import { useNavigate } from "react-router-dom";
import UploadEmergency from "./UploadEmergency";
import UploadEmergencyInfo from "./UploadEmergencyInfo";
import MapBox from "../Map/map";

function PostUpload() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [emergencyType, setEmergencyType] = useState(null);
  const [accountModalId, setAccountModalId] = useState(1);
  const navigate = useNavigate();

  const close = () => {
    setAccountModalId(0);
    navigate(-1);
    onClose();
  };

  const updateModalId = (item) => {
    setAccountModalId(item);
    onOpen();
  };

  const getType = (item) => {
    setEmergencyType(item);
  };
  return (
    <DashboardLayout>
      <div className="w-full">
        <div className="w-[100%]">
          <MapBox />
        </div>
      </div>
      {
        <UploadEmergency
          isOpen={accountModalId === 1 && isOpen}
          onClose={close}
          onOpen={onOpen}
          updateModal={updateModalId}
          setEmergencyCategory={getType}
        />
      }
      {
        <UploadEmergencyInfo
          isOpen={accountModalId === 2 && isOpen}
          onClose={close}
          onOpen={onOpen}
          updateModal={updateModalId}
          emergencyType={emergencyType}
          setEmergencyModalId={updateModalId}
        />
      }
    </DashboardLayout>
  );
}

export default PostUpload;
