import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaUser } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { pickemoji } from "../../constants/images";
import { setEventCommentAction } from "../../redux/peoplePost/peoplepost.actions";
import JWTDecoder, { getRemainingDate } from "../../utils/utils";
import UserPostShare from "./UserPostShare/UserPostShare";
import DashboardLayout from "../DashboardHome/DashboardLayout";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
import ReactMapboxGl, { Marker } from "react-mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
/* eslint-disable import/no-webpack-loader-syntax */
import mapboxgl from "mapbox-gl";

// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const Map = ReactMapboxGl({
  accessToken:
    "pk.eyJ1IjoiYWRtaW5lZmZpY2lvbiIsImEiOiJjbGI2d2JvZHUwNTFsM3ZvZGhmaHYxZ21pIn0.LCwPwD2DbgM0piyDwL4CJw",
  logoPosition: "bottom-right",
});
function ViewSingleUserPost() {
  const [message, setMessage] = useState("");
  const [onReply, setOnReply] = useState(false);
  const [replyId, setReplyId] = useState("");
  const [userData, setUserData] = useState({});
  const [replyMessage, setReplyMessage] = useState("");
  const token = useSelector((state) => state.auth.accessToken);
  const dispatch = useDispatch();
  const [singleEventData, setSingleEventData] = useState({});
  const [editComment, setEditComment] = useState(false);
  const [editMessage, setEditMessage] = useState("");
  const [editCommentId, setEditCommentId] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const getSingleEvent = async () => {
      try {
        const { data } = await axios.get(
          `https://us-central1-aradugbo-ac17a.cloudfunctions.net/api/events/${id}`
        );
        if (data) {
          setSingleEventData(data);
        }
      } catch (err) {}
    };

    getSingleEvent();
    // eslint-disable-next-line
  }, [singleEventData]);

  const handlePostComment = (eventId) => {
    if (message !== "") {
      dispatch(setEventCommentAction(message, eventId));
      setMessage("");
    }
  };

  const showReplyBox = (isTrue, id) => {
    setReplyId(id);
    setOnReply(isTrue);
  };

  const handleReplyComment = async (commentId, username, displayName) => {
    if (replyMessage !== "") {
      const { data } = await axios.post(
        `https://us-central1-aradugbo-ac17a.cloudfunctions.net/api/events/${singleEventData?.eventId}/comment/${commentId}`,
        {
          text: replyMessage,
          replyTo: { username, displayName },
        },
        {
          headers: {
            Accept: "*/*",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (data) {
      }
    }
  };

  const handleOpenEditComment = (comment) => {
    setEditComment(true);
    setEditCommentId(comment.commentId);
    setEditMessage(comment.text);
  };

  useEffect(() => {
    if (token) {
      setUserData(JWTDecoder.decode(token));
    } else {
    }
  }, [token]);

  const handleEditPost = async () => {
    if (editMessage !== "") {
      try {
        const { data } = await axios.post(
          `https://us-central1-aradugbo-ac17a.cloudfunctions.net/api/comment/edit/${editCommentId}`,
          {
            text: editMessage,
          },
          {
            headers: {
              Accept: "*/*",
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (data) {
        }
      } catch (err) {}
    }
  };
  const login = () => {
    navigate("/login");
  };
  return (
    <DashboardLayout>
      <div className="p-0 m-0">
        {singleEventData && (
          <div className="w-full h-[100vh] overflow-hidden flex  flex-col sm:flex-row ">
            {/* slider */}
            <div className=" w-[50%] h-full bg-black">
              <div className="pl-[3rem] flex bg-black items-center">
                <NavLink
                  to="/dashboard/home"
                  className="text-white cursor-pointer flex py-[1.5rem] "
                >
                  <AiOutlineArrowLeft className="text-xl text-white mr-[1rem] " />{" "}
                  Home
                </NavLink>
              </div>
              <div className=" h-full w-full">
                <div className="flex h-full w-full">
                  <img
                    src={singleEventData?.media?.images[0]}
                    className="w-full h-[90%] object-cover"
                    alt="user"
                  />
                </div>
                {/* dots */}
              </div>
            </div>
            {/* post info */}
            <div className="w-[50%] overflow-y-scroll h-full flex flex-col justify-between bg-white">
              {/* single message */}
              <div className="w-full px-[37px]">
                <div className="flex justify-between mt-[30px]">
                  <div className="text-[20px] text-[#253855] font-semibold">
                    {singleEventData?.title}
                  </div>
                  <div>...</div>
                </div>
                <div className="mt-2 text-[14px] text-[#425466]">
                  {singleEventData?.text}
                </div>
                <div className=" mt-2  mr-[1rem] pb-4">
                  {singleEventData?.coords && (
                    <Map
                      // eslint-disable-next-line
                      onZoom={(item, e) => {
                        item.flyTo({
                          center: [
                            singleEventData?.coords?.latitude,
                            singleEventData?.coords?.longitude,
                          ],
                        });
                      }}
                      zoom={[3]}
                      movingMethod="flyTo"
                      pitch={[10]}
                      boxZoom={true}
                      renderChildrenInPortal={true}
                      // eslint-disable-next-line
                      style="mapbox://styles/mapbox/streets-v8?optimize=false"
                      center={[
                        singleEventData?.coords?.latitude,
                        singleEventData?.coords?.longitude,
                      ]}
                      containerStyle={{
                        height: "200px",
                        width: "100%",
                      }}
                    >
                      <Marker
                        coordinates={[
                          singleEventData?.coords?.latitude,
                          singleEventData?.coords?.longitude,
                        ]}
                        // anchor="top"
                        className=" w-[10px] h-[10px] rounded-[50%] bg-white p-[.1rem]"
                      >
                        <div className="bg-red-500  rounded-[50%] w-full h-full"></div>
                      </Marker>
                    </Map>
                  )}
                </div>
                <div className="-mx-4">
                  {token !== undefined && (
                    <UserPostShare
                      page="shared"
                      selectedPost={singleEventData}
                      user={userData}
                      commentCount={singleEventData?.commentCount}
                      likeCount={singleEventData?.likeCount}
                    />
                  )}
                </div>
                <div className="w-full mt-[2rem] ">
                  <div className="text-[#425466] mb-2">
                    Comments ({singleEventData?.commentCount})
                  </div>
                  <div className="w-full">
                    {singleEventData?.commentCount > 0
                      ? singleEventData?.comments.map((data, index) => (
                          <div className="w-full flex mb-[12px]">
                            <div className="flex">
                              <div className="mr-2">
                                <img
                                  alt="user"
                                  className="w-[40px] h-[40px] rounded-full"
                                  src={data.userImage}
                                />
                              </div>
                              <div className="w-full">
                                <div className="flex items-center">
                                  <div className="mr-[10px]">
                                    {data.username}
                                  </div>
                                  <div> </div>
                                  <div className="text-[12px] text-[#718096]">
                                    {getRemainingDate(data.createdAt)}
                                  </div>
                                </div>
                                {/* comment message */}
                                <div className="text-[13px] text-[#425466]">
                                  {data.text}
                                </div>
                                <div className="flex items-center ">
                                  <div
                                    className="cursor-pointer hover:bg-[#f2f2f2] p-[5px]"
                                    onClick={() =>
                                      showReplyBox(true, data.commentId)
                                    }
                                  >
                                    Reply
                                  </div>
                                  <div className="mx-2">.</div>
                                  <div className="text-[#7d7d7e] text-[12px]">
                                    {data.nestedCommentCount} Reply
                                  </div>
                                </div>
                                {onReply && replyId === data.commentId && (
                                  <div className="w-full flex bg-[#F7F7F7] mt-2 rounded-[31px] px-2">
                                    <div className="w-full h-[44px] ">
                                      <input
                                        className="w-full h-full bg-transparent border-none outline-none px-2"
                                        value={replyMessage}
                                        placeholder="Reply...."
                                        onChange={(e) =>
                                          setReplyMessage(e.target.value)
                                        }
                                      />
                                    </div>
                                    <button
                                      className="text-[#FCBE5A]"
                                      onClick={() =>
                                        handleReplyComment(
                                          data.commentId,
                                          data.username,
                                          data.displayName
                                        )
                                      }
                                    >
                                      Send
                                    </button>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ))
                      : null}
                  </div>
                </div>
              </div>
              {/* chat box */}
              {!editComment && (
                <div
                  className={`w-full flex items-center justify-self-end border-t ${
                    token === "" ? "bg-[#FCFCFD]" : "bg-white"
                  } border-[#EAECF0] pl-[13px]`}
                >
                  {token === undefined ? (
                    <div
                      onClick={login}
                      className="w-full h-full my-[.5rem] flex items-center"
                    >
                      <div className="mr-[13px]">
                        <img src={pickemoji} alt="emohi" />
                      </div>
                      <div className="w-[402px] h-full bg-[#F7F7F7] rounded-[20px]">
                        <input
                          className="border-none outline-none w-full h-full"
                          placeholder="Log in to post a comment"
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="w-full h-full  my-[.5rem] flex items-center">
                      <div className="w-[44px] h-[44px] bg-[#FCBE5A] rounded-full flex justify-center items-center">
                        <FaUser />
                      </div>
                      <div className="w-full flex bg-[#F7F7F7] rounded-[31px] mx-3 px-2">
                        <div className="w-full h-[44px] ">
                          <input
                            className="w-full h-full bg-transparent border-none outline-none px-2"
                            value={message}
                            placeholder="Add a comment...."
                            onChange={(e) => setMessage(e.target.value)}
                          />
                        </div>
                        <button
                          className="text-[#FCBE5A]"
                          onClick={() =>
                            handlePostComment(singleEventData?.eventId)
                          }
                        >
                          Post
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
              {editComment && (
                <div
                  className={`w-full py-[1rem] flex items-center justify-self-end border-t ${
                    token === "" ? "bg-[#FCFCFD]" : "bg-white"
                  } border-[#EAECF0] pl-[13px]`}
                >
                  {token === undefined ? (
                    <div className="w-full my-[1rem] bg-red-400 flex items-center">
                      <div className="mr-[13px]">
                        <img src={pickemoji} alt="emoji" />
                      </div>
                      <div className="w-[402px] h-full bg-[#F7F7F7] rounded-[20px]">
                        <input
                          className="border-none outline-none w-full h-full"
                          placeholder="Log in to post a comment"
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="w-full my-[1rem] bg-red-400  flex items-center">
                      <div className="w-[44px] h-[44px] bg-[#FCBE5A] rounded-[50%] flex justify-center items-center">
                        <FaUser />
                      </div>
                      <div className="w-full flex bg-[#F7F7F7] rounded-[31px] mx-3 px-2">
                        <div className="w-full h-[44px] ">
                          <input
                            className="w-full h-full bg-transparent border-none outline-none px-2"
                            value={editMessage}
                            placeholder="Edit Comment...."
                            onChange={(e) => setEditMessage(e.target.value)}
                          />
                        </div>
                        <button
                          className="text-[#FCBE5A]"
                          onClick={() =>
                            handleEditPost(singleEventData?.eventId)
                          }
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
}

export default ViewSingleUserPost;
